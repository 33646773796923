<template>
  <div>
    <v-toolbar flat>
      <!-- <v-toolbar-title>{{ $t('title.user_list') }}</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <div class="card-header-form">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="params.search"
            placeholder="Search"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="fetchListUsers(params)"
              style="
                border-radius: 0px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- <button
        class="ml-2 btn btn-primary"
        @click="onCreateDialog"
        v-if="checkUserPermission('CREATE')"
        style="border-radius: 50px"
      >
        <i class="fas fa-user-plus" style="border-radius: 0 !important"></i>
      </button> -->
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },

    async deleteItemConfirm() {
      await this.deleteUser({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      this.fetchListUsers(this.params);
      this.dialogDelete = false;
    },

    // async onCreateDialog() {
    //   await this.fetchAllRolesDependOnUser();
    //   this.fetchPopUp(true);
    // },
    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", ["fetchPopUp", "fetchListUsers", "deleteUser"]),
  },
};
</script>

<style >
.input-group .v-input__slot {
  width: 60% !important;
  align-items: end !important;
  align-self: self-end;
}

.uneditable-input:focus,
input:focus,
input[type]:focus,
textarea:focus {
  box-shadow: 0px;
}

.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 14px;
  padding: 5px 15px;
  height: 34px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  height: 36px !important;
}
</style>
